// BIBLIOTECAS

import React from "react";
import {useState, useEffect} from 'react'
import '@lottiefiles/lottie-player';
import {Dropdown, Carousel} from 'react-bootstrap';
import emailjs from '@emailjs/browser'


// CERTIFICADOS

import CleanCode from './assets/cleanCode.png'
import AdvancedPentest from './assets/AdvancedPentest.png'
import FundamentosHardware from './assets/Fundamentos_hardware.png'
import FundamentosSegurancaWifi from './assets/Fundamentos_Seguranca_redes_sem_fio.png'
import HtmlCssPratica from './assets/html_Css_pratica.png'
import HtmlBasico from './assets/HtmlBasico.png'
import IntroducaoDesignGrafico from './assets/introducao_design_grafico.png'
import IntroducaoHackingSolyd from './assets/Introducao_hacking_Solyd.png'
import Wpscan from './assets/Wpscan.png'
import Photoshop from './assets/photoshop.png'
import Nmap from './assets/Nmap.png'
import Shodan from './assets/Shodan.png'
import KaliLinuxEsecurity from './assets/KaliLinux_Esecurity.png'
import OrigamidUIIniciantes from './assets/Origamid-UI-Iniciantes.png'

// PROJETOS
import StudyChat from './assets/studychat.png'
import SpMedical from './assets/SpMedical.jpg'
import LandingPageSenai from './assets/landingPageSenai.png'
import Instadev from './assets/instadev.png'
import Eplayers from './assets/eplayers.png'
import NotaMil from './assets/notamil.png'

// OUTROS 

import seta from './assets/seta.png'
import logo from './assets/logo.png';
import bars from './assets/bars.png'
import './App.css';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-253500850-1"; 
ReactGA.initialize(TRACKING_ID);
  

export default function App(){

  const [certificados, setCertificados] = useState([]);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [descricao, setDescricao]  = useState("");


  const enviarEmail = (e) => {
    e.preventDefault()

    if (descricao !== '' & nome !== '' & email !== '' ) {
      
      const parametrosEmail = {
        from_name: nome,
        email_remetente: email,
        message: descricao
      }

      emailjs.send("service_76f1ocl", "template_gv1lmn3", parametrosEmail, "_YqMJxUUYbKWv7b_d")
      .then((response) => {
        alert("email enviado com sucesso")
        console.log("Email enviadooooooooo", response.status, response.text)
        setDescricao("")
        setNome("")
        setEmail("")
      })
    }

  }

  const listarProjetos =  () => {
    const projetosArray = [
      {
        link: "https://github.com/Lucas-M3dina/NotaMil",
        img: NotaMil,
        nome: "NotaMil"
      },
      
      {
        link: "https://github.com/Lucas-M3dina/StudyChat",
        img: StudyChat,
        nome: "StudyChat"
      },

      {
        link: "https://github.com/Lucas-M3dina/SP_Medical_Group",
        img: SpMedical,
        nome: "SPMedicalGroup"
      },

      {
        link: "https://github.com/Lucas-M3dina/LandingPageSenai",
        img: LandingPageSenai,
        nome: "Landing Page Senai"
      },

      {
        link: "https://github.com/Lucas-M3dina/InstaDev",
        img: Instadev,
        nome: "Instadev"
      },

      {
        link: "https://github.com/Lucas-M3dina/EPlayersMVC",
        img: Eplayers,
        nome: "Eplayers"
      }
    ]

    return projetosArray;
  }


  const listarCertificados = () => {
    const certificadosArray = [
      {
        instituicao: "Digital Innovation One", 
        curso: "Introdução a Clean Code e SOLID", 
        certificado: CleanCode, 
        descricao: "Nesse curso pude reforçar conceitos ensinados no técnico, e  observar cada item do SOLID e sua aplicação trabalhando assim na teoria e na prática" ,
      
        instituicao2: "eSecurity - Cyber Security", 
        curso2: "Advanced Penetration Test ", 
        certificado2: AdvancedPentest, 
        descricao2: "Nesse curso meu principal foco foi entender principalmente falhas em sistemas web e serviu sem duvida para o aprendizado de falhas mais comuns e para me instigar a procurar falhas mais avançadas" ,
      },

      {
        instituicao: "Fundação Bradesco", 
        curso: "Fundamentos de TI: Hardware e Software", 
        certificado: FundamentosHardware, 
        descricao: "Nesse curso aprendi sobre a historia e a evolução dos computadores tendo como enfâse o hardware, ja como software foi possivel ver de forma superficial sistemas operacionais" ,
      
        instituicao2: "Udemy", 
        curso2: "Fundamentos de segurança em redes sem fio", 
        certificado2: FundamentosSegurancaWifi, 
        descricao2: "No curso foi possível observar um pouco da historia das redes sem fio, protocolos de segurança, site survey, e principalmente técnicas de hacking em Wi-Fi" ,
      },

      {
        instituicao: "Fundação Bradesco", 
        curso: "HTML e CSS na prática", 
        certificado: HtmlCssPratica, 
        descricao: "O curso mostrou especificações sobre diversas propriedades além de propor exemplos práticos para fixação dos conteúdos, pude assim aprimorar o uso de ambas as tecnologias " ,
      
        instituicao2: "Fundação Bradesco", 
        curso2: "HTML - Básico", 
        certificado2: HtmlBasico, 
        descricao2: "O curso mostrou as tags HTML essenciais reforçando e agregando ao conhecimento passado no técnico" ,
      },

      {
        instituicao: "Fundação Bradesco", 
        curso: "Fundamentos do Design Gráfico", 
        certificado: IntroducaoDesignGrafico, 
        descricao: "No curso pude ver um pouco sobre composição, produção e tratamento de imagens, teoria das cores e tipografia. Além de ter contato com editores de imagem" ,
      
        instituicao2: "SOLYD", 
        curso2: "Introdução ao Hacking e Pentest", 
        certificado2: IntroducaoHackingSolyd, 
        descricao2: "Durante o curso aprendi como parte teórica as fases do pentest, mercado de trabalho, certificações, ja tendo em vista a parte pratica além de utilizar o Linux pude fixar o conhecimento aplicando cada falha demonstrada em um ambiente controlado" ,
      },

      {
        instituicao: "ACADI-TI", 
        curso: "WPSCAN", 
        certificado: Wpscan, 
        descricao: "O curso tinha como objetivo extrair e adquirir conhecimento acerca da ferramenta WPSCAN utilizada para encontrar falhas em aplicações Wordpress" ,
      
        instituicao2: "Prime Cursos", 
        curso2: "Photoshop", 
        certificado2: Photoshop, 
        descricao2: "Nesse curso pude aprender mais acerca de edição de imagem e embora esse curso tenha como ferramenta o Photoshop os conhecimentos adquiridos facilitaram o uso de ferramentas como o Figma" ,
      },

      {
        instituicao: "Udemy", 
        curso: "NMAP", 
        certificado: Nmap, 
        descricao: "O curso tinha como objetivo extrair e adquirir conhecimento acerca da ferramenta NMAP utilizado para reconhecimento" ,
      
        instituicao2: "ACADI-TI", 
        curso2: "SHODAN", 
        certificado2: Shodan, 
        descricao2: "Nesse curso pude aprender mais sobre a ferramenta SHODAN que possibilita o encontro de dispositivos conectados a internet e como usa-la para reconhecimento" ,
      },

      {
        instituicao: "eSecurity", 
        curso: "Kali Linux for Beginners", 
        certificado: KaliLinuxEsecurity, 
        descricao: "O curso tinha como objetivo familiarizar o aluno com o sistema Linux e algumas ferramentas de hacking" ,
      
        instituicao2: "Origamid", 
        curso2: "UI para iniciantes", 
        certificado2: OrigamidUIIniciantes, 
        descricao2: "Nesse curso aprendi mais sobre Design de Interface de Usuário, o curso mostrou conceitos como tipografia até otimização de arquivos, o aprendizado desse curso foi fixado por meio de dois layouts feitos no Figma" ,
      },


    ];

    return certificadosArray;

  }

  const listarCertificadosResponsivo = () => {
    const certificadosArrayResponsivo = []

    listarCertificados().map( (certificado) => {
      var objCertificado1 = {
        instituicao: certificado.instituicao, 
        curso:  certificado.curso, 
        certificado: certificado.certificado, 
        descricao:  certificado.descricao,
      }

      var objCertificado2 = {
        instituicao: certificado.instituicao2, 
        curso:  certificado.curso2, 
        certificado: certificado.certificado2, 
        descricao:  certificado.descricao2,
      }


      certificadosArrayResponsivo.push(objCertificado1)
      certificadosArrayResponsivo.push(objCertificado2)
    })

    return certificadosArrayResponsivo
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])
  

 



  
  return (
    <>
      
      <header>
        <img className='logo-header' src={logo}  alt="logo"/>
        <nav className='container-titulos-header'>
          <span onClick={() => {
            window.location.href = '#contato'; 
            ReactGA.event({
              category : "header", 
              action : "Click", 
              label : "Clicou no contato do header"});
            }} className="titulo-header esconder">CONTATO</span>

          <span onClick={() => {
            window.location.href = '#certificados';
            ReactGA.event({
              category : "header", 
              action : "Click", 
              label : "Clicou em Certificados no header"});
            }} className="titulo-header esconder">CERTIFICADOS</span>

          <span onClick={() => {
            window.location.href = '#projeto';
            ReactGA.event({
              category : "header", 
              action : "Click", 
              label : "Clicou em projetos no header"});
            }} className="titulo-header esconder">PROJETOS</span>

          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <img src={bars} alt="Menu"/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item onClick={() => {
              ReactGA.event({
                category : "header", 
                action : "Click", 
                label : "Clicou em contato no header"});
            }} className="titulo-header" href="#contato">CONTATO</Dropdown.Item>

            <Dropdown.Item onClick={() => {
              ReactGA.event({
                category : "header", 
                action : "Click", 
                label : "Clicou em Certificados no header"});
            }} className="titulo-header" href="#certificados">CERTIFICADOS</Dropdown.Item>

            <Dropdown.Item onClick={() => {
              ReactGA.event({
                category : "header", 
                action : "Click", 
                label : "Clicou em projetos no header"});
            }} className="titulo-header" href="#projeto">PROJETOS</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          
        </nav>
      </header>

      <main>

        <section className="banner-apresentacao">
          <div className="container-apresentacao">
            <lottie-player
              src="https://assets10.lottiefiles.com/packages/lf20_ndj9fzcd.json"
              autoplay
              loop
              id='astronauta'
            />

            <div className="textos-apresentacao">
              <button className="btn-bemvindo">Bem vindo ao meu portfólio !</button>
              <p className="titulo-apresentacao">Meu nome é Lucas e sou  desenvolvedor de sistemas</p>
              <p className="texto-apresentacao">Tenho 18 anos e sou um apaixonado por tecnologia, fui aluno de Desenvolvimento de Sistemas no Senai de Informática lugar onde percebi que dentro da infinidade de coisas que tecnologia abrange a paixão por programação era muito mais intensa do que as demais.</p>
              <span onClick={() => {window.location.href = '#contato'}} className="contato-apresentacao">Entre em contato <img className="seta" src={seta} alt="Seta"/></span>
            </div>
          </div>
        
        </section>

        <section id="certificados" className="certificados">

          <Carousel variant="dark" className="container-certificados">
          {
              
            listarCertificados().map( (c) => {
              return(
                
                  <Carousel.Item >
                    <div className="card-certificados">
                      <span className="instituicao-certificados">{c.instituicao}</span>
                      <span className="curso-certificados">{c.curso}</span>
                      <img className="foto-certificado" src={c.certificado} alt="certificado" />
                      <span className="descricaoCurso-certificados">{c.descricao}</span>
                    </div>
        
                    <div className="card-certificados">
                      <span className="instituicao-certificados">{c.instituicao2}</span>
                      <span className="curso-certificados">{c.curso2}</span>
                      <img className="foto-certificado" src={c.certificado2} alt="certificado" />
                      <span className="descricaoCurso-certificados">{c.descricao2}</span>
                    </div>
                  </Carousel.Item>
                
              )
            })
          }
            
          </Carousel>

          <Carousel variant="dark" className="container-certificados-responsivo">
          {
              
            listarCertificadosResponsivo().map( (c) => {
              return(
                
                  <Carousel.Item >
                    <div className="card-certificados">
                      <span className="instituicao-certificados">{c.instituicao}</span>
                      <span className="curso-certificados">{c.curso}</span>
                      <img className="foto-certificado" src={c.certificado} alt="certificado" />
                      <span className="descricaoCurso-certificados">{c.descricao}</span>
                    </div>
                  </Carousel.Item>
                
              )
            })
          }
            
          </Carousel>

        </section>
        
        <section id="projeto" className="projeto">
          <div className="banner-projeto">
            <h1 className="titulo-projeto">PROJETOS</h1>
            <p className="texto-projeto">Sempre pensei que apenas o conhecimento teórico não é o suficiente para um aprendizado completo por isso abaixo estão alguns projetos desenvolvidos por mim </p>
          </div>
          <div className="listagem-projeto">
            <div className="container-projetos">
              {
                listarProjetos().map( (p) => {
                  return(
                    <button onClick={() => {
                      window.open(p.link, '_blank');
                      ReactGA.event({
                        category : "Projetos", 
                        action : "Click", 
                        label : "Abriu o projeto " + p.nome});
                      }} className="card-projetos cardOff">
                      <img src={p.img} alt={p.nome}/>
                    </button>
                  )})
              }
              
              <Carousel className="container-certificados-responsivo">
                {
                  
                  listarProjetos().map( (p) => {
                    return(
                      <Carousel.Item >
                        <div className="container-carroussel-projeto">
                          <button onClick={() => {
                            window.open(p.link, '_blank')
                            ReactGA.event({
                              category : "Projetos", 
                              action : "Click", 
                              label : "Abriu o projeto" + p.nome});
                            }} className="card-projetos">
                            <img src={p.img} alt={p.nome}/>
                          </button>
                        </div>
                      </Carousel.Item>
                    )})
                }
              </Carousel>
            
              
            </div>
          </div>
        </section>

        <section id="contato" className="contato">
          <div className="banner-contato">
            <p className="titulo-contato">Deseja entrar em contato?</p>
            <div className="container-contato">
              <lottie-player
                src="https://assets9.lottiefiles.com/packages/lf20_zj3qnsfs.json"
                autoplay
                loop
                id='animacao-contato'
              />

              <form onSubmit={enviarEmail} className="form-contato">
                <div className="container-input-contato">
                  <input className="input-nome-email" type="text" name="nome" value={nome}  placeholder="Nome" required/>
                  <input className="input-nome-email" type="email" name="email" value={email}  placeholder="Email" required/>
                </div>
                <textarea type="text" name="descricao" id="descricao" value={descricao}  placeholder="Deixe sua mensagem !!!" required/>
                <button className="btn-contato" type="submit" onClick={() => {
                  ReactGA.event({
                    category : "Contato", 
                    action : "Email", 
                    label : nome +  " enviou uma mensagem"});
                }}>ENVIAR</button>
              </form>
            </div>
            
          </div>
        </section>

      </main>

      <footer>
        <span>Todos os direitos reservados</span>
      </footer>

    </>
  );
  

}
